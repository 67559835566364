export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'

export const PRODUCT_NEW_REQUEST = 'PRODUCT_NEW_REQUEST'
export const PRODUCT_NEW_SUCCESS = 'PRODUCT_NEW_SUCCESS'
export const PRODUCT_NEW_FAIL = 'PRODUCT_NEW_FAIL'

export const PRODUCT_ARRIVAL_REQUEST = 'PRODUCT_ARRIVAL_REQUEST'
export const PRODUCT_ARRIVAL_SUCCESS = 'PRODUCT_ARRIVAL_SUCCESS'
export const PRODUCT_ARRIVAL_FAIL = 'PRODUCT_ARRIVAL_FAIL'

export const PRODUCT_CATEGORY_REQUEST = 'PRODUCT_CATEGORY_REQUEST'
export const PRODUCT_CATEGORY_SUCCESS = 'PRODUCT_CATEGORY_SUCCESS'
export const PRODUCT_CATEGORY_FAIL = 'PRODUCT_CATEGORY_FAIL'

export const PRODUCT_LAPTOP_REQUEST = 'PRODUCT_LAPTOP_REQUEST'
export const PRODUCT_LAPTOP_SUCCESS = 'PRODUCT_LAPTOP_SUCCESS'
export const PRODUCT_LAPTOP_FAIL = 'PRODUCT_LAPTOP_FAIL'

export const PRODUCT_PHONE_REQUEST = 'PRODUCT_PHONE_REQUEST'
export const PRODUCT_PHONE_SUCCESS = 'PRODUCT_PHONE_SUCCESS'
export const PRODUCT_PHONE_FAIL = 'PRODUCT_PHONE_FAIL'

export const PRODUCT_OFFICE_REQUEST = 'PRODUCT_OFFICE_REQUEST'
export const PRODUCT_OFFICE_SUCCESS = 'PRODUCT_OFFICE_SUCCESS'
export const PRODUCT_OFFICE_FAIL = 'PRODUCT_OFFICE_FAIL'

export const PRODUCT_OTHERS_REQUEST = 'PRODUCT_OTHERS_REQUEST'
export const PRODUCT_OTHERS_SUCCESS = 'PRODUCT_OTHERS_SUCCESS'
export const PRODUCT_OTHERS_FAIL = 'PRODUCT_OTHERS_FAIL'

export const PRODUCT_RELATED_REQUEST = 'PRODUCT_RELATED_REQUEST'
export const PRODUCT_RELATED_SUCCESS = 'PRODUCT_RELATED_SUCCESS'
export const PRODUCT_RELATED_FAIL = 'PRODUCT_RELATED_FAIL'