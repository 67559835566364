export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const SUB_USER_REGISTER_REQUEST = 'SUB_USER_REGISTER_REQUEST'
export const SUB_USER_REGISTER_SUCCESS = 'SUB_USER_REGISTER_SUCCESS'
export const SUB_USER_REGISTER_FAIL = 'SUB_USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const DISABLE_REQUEST = 'DISABLE_REQUEST'
export const DISABLE_SUCCESS = 'DISABLE_SUCCESS'
export const DISABLE_FAIL = 'DISABLE_FAIL'
export const DISABLE_RESET = 'DISABLE_RESET'

export const ACTIVATE_REQUEST = 'ACTIVATE_REQUEST'
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS'
export const ACTIVATE_FAIL = 'ACTIVATE_FAIL'
export const ACTIVATE_RESET = 'ACTIVATE_RESET'

export const USER_ISSUE_REQUEST = 'USER_ISSUE_REQUEST'
export const USER_ISSUE_SUCCESS = 'USER_ISSUE_SUCCESS'
export const USER_ISSUE_FAIL = 'USER_ISSUE_FAIL'
export const USER_ISSUE_RESET = 'USER_ISSUE_RESET'

export const USER_LIST_ISSUE_REQUEST = 'USER_LIST_ISSUE_REQUEST'
export const USER_LIST_ISSUE_SUCCESS = 'USER_LIST_ISSUE_SUCCESS'
export const USER_LIST_ISSUE_FAIL = 'USER_LIST_ISSUE_FAIL'
export const USER_LIST_ISSUE_RESET = 'USER_LIST_ISSUE_RESET'
