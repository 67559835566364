import React, { useState } from 'react'
import {Link, NavLink} from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Collapse,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import { logout } from '../actions/userActions'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const cart = useSelector((state) => state.cart)
    const { cartItems } = cart

    const logoutHandler = () => {
        dispatch(logout())
    }

    const closeNavbar = () => {
        setIsOpen(false)
    }

    return (
        <header className="header_area">
            <div className="main_menu">
                <Navbar expand="xl">
                    <Container>
                        <Link to="/" className="navbar-brand mr-auto">
                            <img alt="logo" src='/images/logo.png' />
                        </Link>

                        <NavLink to="/search" className='d-xl-none ml-auto mr-4 cart-mobile'>
                            <i className="fa fa-search"></i>
                        </NavLink>
                        <NavLink to="/cart" className='d-xl-none ml-auto mr-4 cart-mobile' style={{position: 'relative'}}>
                            <i className="fa fa-shopping-cart"></i>
                            <span className="nav-shop__circle">{cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}</span>
                        </NavLink>

                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={toggle}
                            aria-expanded={isOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                        
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="ml-auto mr-auto" navbar>
                                <NavItem>
                                    <NavLink to="/about" onClick={closeNavbar} className='nav-link'>About Us</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/new-arrivals" onClick={closeNavbar} className='nav-link'>New Arrivals</NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar className='submenu'>
                                    <DropdownToggle nav caret>
                                        Categories
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <LinkContainer to='/categories/laptops' onClick={closeNavbar}>
                                            <DropdownItem>  
                                                Laptops
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/phones' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Phone and Accessories
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/networking' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Networking
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/servers' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Servers
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/softwares' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Softwares
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/office-equipments' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Office Equipments
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/sound-gadgets' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Sound Gadgets
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/laptop-accessories' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Laptop Accessories
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/electronics' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Electronics
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/desktops' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Desktops
                                            </DropdownItem>
                                        </LinkContainer>
                                        <LinkContainer to='/categories/gaming' onClick={closeNavbar}>
                                            <DropdownItem>
                                                Gaming
                                            </DropdownItem>
                                        </LinkContainer>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <NavLink to="/contact" className='nav-link' onClick={closeNavbar}>Contact Us</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="ml-auto nav-second" navbar>
                                <NavItem className="d-none d-xl-inline">
                                    <NavLink to="/search" className='nav-link'>
                                        <i className="fa fa-search"></i>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="d-none d-xl-inline">
                                    <NavLink to="/cart" className='nav-link btn-cart' style={{position: 'relative'}}>
                                        Cart <i className="fa fa-shopping-cart"></i>
                                        <span className="nav-shop__circle">{cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}</span>
                                    </NavLink>
                                </NavItem>
                                {userInfo ? (
                                    <UncontrolledDropdown nav inNavbar className='submenu'>
                                        <DropdownToggle nav caret>
                                            {userInfo.name} <i className="fa fa-angle-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <LinkContainer to='/profile' onClick={closeNavbar}>
                                                <DropdownItem>  
                                                    Profile
                                                </DropdownItem>
                                            </LinkContainer>
                                            {userInfo.isAdmin && (
                                                <>
                                                    <LinkContainer to='/admin/users' onClick={closeNavbar}>
                                                        <DropdownItem>  
                                                            Users
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                    <LinkContainer to='/admin/products' onClick={closeNavbar}>
                                                        <DropdownItem>  
                                                            Products
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                    <LinkContainer to='/admin/orders' onClick={closeNavbar}>
                                                        <DropdownItem>  
                                                            Orders
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                    <LinkContainer to='/admin/issues' onClick={closeNavbar}>
                                                        <DropdownItem>  
                                                            Issues
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </>
                                            )}
                                            
                                            <DropdownItem onClick={logoutHandler}>
                                                Log Out
                                            </DropdownItem>
                                            
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    ) : (
                                    <NavItem>
                                        <NavLink to="/sign-in" className='nav-link btn-cart'>Log In</NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        </header>
    )
}

export default Header