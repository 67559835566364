import axios from 'axios'
import {
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_RESET,
    USER_LIST_SUCCESS,
    USER_ISSUE_FAIL,
    USER_ISSUE_REQUEST,
    USER_ISSUE_SUCCESS,
    USER_LIST_ISSUE_FAIL,
    USER_LIST_ISSUE_REQUEST,
    USER_LIST_ISSUE_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    SUB_USER_REGISTER_REQUEST,
    SUB_USER_REGISTER_SUCCESS,
    SUB_USER_REGISTER_FAIL,
    DISABLE_REQUEST,
    DISABLE_SUCCESS,
    DISABLE_FAIL,
    ACTIVATE_REQUEST,
    ACTIVATE_SUCCESS,
    ACTIVATE_FAIL,
} from '../constants/userConstants'
import { ORDER_LIST_MY_RESET } from '../constants/orderConstants'

const mainUrl = 'https://betadisney-backend.vercel.app'

export const login = (email, password) => async (dispatch) => {
  try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `${mainUrl}/api/users/login`,
            { email, password },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('cartItems')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('paymentMethod')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: ORDER_LIST_MY_RESET })
    dispatch({ type: USER_LIST_RESET })
    document.location.href = '/sign-in'
}

export const register = (name, email, password) => async (dispatch) => {
    try {
        dispatch({
        type: USER_REGISTER_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `${mainUrl}/api/users`,
            { name, email, password },
            config
        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data,
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const subUserCreation = (name, email, password) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUB_USER_REGISTER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `${mainUrl}/api/users/sub-admin`,
            { name, email, password },
            config
        )

        dispatch({
            type: SUB_USER_REGISTER_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: SUB_USER_REGISTER_FAIL,
            payload: message,
        })
    }
}

export const activateUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ACTIVATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(`${mainUrl}/api/users/${id}/activate`,{}, config)

        dispatch({
            type: ACTIVATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: ACTIVATE_FAIL,
            payload: message,
        })
    }
}

export const disableUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DISABLE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(`${mainUrl}/api/users/${id}/disable`,{}, config)

        dispatch({
            type: DISABLE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: DISABLE_FAIL,
            payload: message,
        })
    }
}

export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`${mainUrl}/api/users/${id}`, config)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: message,
        })
    }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PROFILE_REQUEST,
        })
    
        const {
            userLogin: { userInfo },
        } = getState()
    
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
    
        const { data } = await axios.put(`${mainUrl}/api/users/profile`, user, config)
  
        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data,
        })
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })
        localStorage.setItem('userInfo', JSON.stringify(data))
    }catch (error) {
        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: message,
        })
    }
}
  
export const listUsers = (pageNumber = '') => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST,
        })
    
        const {
            userLogin: { userInfo },
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
    
        const { data } = await axios.get(`${mainUrl}/api/users?pageNumber=${pageNumber}`, config)
  
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data,
        })
    }catch (error) {
        console.log(error)
        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_LIST_FAIL,
            payload: message,
        })
    }
}

export const submitIssue = (name, email, order, subject, details) => async (dispatch) => {
    try {
        dispatch({
            type: USER_ISSUE_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `${mainUrl}/api/contact`,
            { name, email, order, subject, details },
            config
        )

        dispatch({
            type: USER_ISSUE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: USER_ISSUE_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const listIssues = (pageNumber = '') => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_ISSUE_REQUEST,
        })
    
        const {
            userLogin: { userInfo },
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
    
        const { data } = await axios.get(`${mainUrl}/api/contact/all?pageNumber=${pageNumber}`, config)
  
        dispatch({
            type: USER_LIST_ISSUE_SUCCESS,
            payload: data,
        })
    }catch (error) {
        console.log(error)
        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_LIST_ISSUE_FAIL,
            payload: message,
        })
    }
}

